// react import
import { useEffect, useState } from "react";

// logos import
import extendedLogo from "../assets/aside/logo_extended.png";
import normalLogo from "../assets/aside/logo_normal.png";
import versionLogo from "../assets/aside/version.png";
import linkedInLogo from "../assets/aside/linkedin.png";
import githubLogo from "../assets/aside/github.png";
import mailLogo from "../assets/aside/mail.png";
import upWorkLogo from "../assets/aside/upwork.png";
import lightTheme from "../assets/aside/theme/theme_light.png";
import darkTheme from "../assets/aside/theme/theme_dark.png";
import enLangLogo from "../assets/aside/lang/lang_en.png";
import frLangLogo from "../assets/aside/lang/lang_fr.png";

//helpers import
import { changeGlobalTheme, changeLanguage, isDarkTheme } from "../helpers/functions";
import { usefulLinks } from "../config/global-config";

const SideBar = ({ lang,updateFnc,setLang }) => {
  const [isExtended, setIsExtended] = useState(false);
  const [themeLogo, setThemeLogo] = useState(null);
  let logo = isExtended === true ? extendedLogo : normalLogo;

  useEffect(() => {
    if (isDarkTheme()) {
      setThemeLogo(darkTheme);
    } else {
      setThemeLogo(lightTheme);
    }
  });

  let imgClick = (e) => {
    setIsExtended(!isExtended);
  };

  return (
    <aside className="aside-bar">
      {isExtended && (
        <div className="aside-upper-part">
          {/* TODO Change Language and change Website Version */}
          <img src={versionLogo} alt="Logo Aside" className="aside-img" />
          <img
            onClick={() => {
              changeGlobalTheme();
              updateFnc();
            }}
            src={themeLogo}
            alt="Logo Aside"
            className="aside-img"
          />
          {lang === "fr" && (
            <img
              src={enLangLogo}
              onClick={() => {
                changeLanguage("en", setLang);
              }}
               alt="Logo Aside"
              className="aside-img"
            />
          )}
          {lang === "en" && (
            <img
              src={frLangLogo}
              onClick={() => {
                changeLanguage("fr", setLang);
              }}
              alt="Logo Aside"
              className="aside-img"
            />
          )}
        </div>
      )}

      <img
        onClick={imgClick}
        src={logo}
        alt="Logo Aside"
        className="aside-img-main"
      />

      {isExtended && (
        <div className="aside-right-part">
          <img
            onClick={() => {
              window.open(usefulLinks.linkedin, "_blank");
            }}
            src={linkedInLogo}
            alt="Logo Aside"
            className="aside-img"
          />
          <img
            onClick={() => {
              window.open(usefulLinks.github, "_blank");
            }}
            src={githubLogo}
            alt="Logo Aside"
            className="aside-img"
          />
          <img
            onClick={() => {
              window.open(usefulLinks.upwork, "_blank");
            }}
            src={upWorkLogo}
            alt="Logo Aside"
            className="aside-img"
          />
          <img
            onClick={() => {
              window.open(usefulLinks.mail, "_blank");
            }}
            src={mailLogo}
            alt="Logo Aside"
            className="aside-img"
          />
        </div>
      )}
    </aside>
  );
};

export default SideBar;

//helpers
import { useEffect, useRef } from "react";
import { usefulLinks } from "../config/global-config";
import { useState } from "react";
import { errorToast, infoToast, succesToast, translate } from "../helpers/functions";
import contactImg from '../assets/contact/contact_me.png';
import axios from "axios";

/* TODO MAKE FLASH MESSAGES WITH TIPS */
const ContactPage = (props) => {
  const mailSection = useRef(null);
  const phoneSection = useRef(null);
  const [actualSection, setActualSection] = useState("mail");

  const displaySection = (section) => {
    switch (section) {
      case "phone":
        mailSection.current.style.display = "none";
        phoneSection.current.style.display = "flex";
        break;
      case "mail":
        phoneSection.current.style.display = "none";
        mailSection.current.style.display = "block";
        break;
      default:
        break;
    }
    setActualSection(section);
  };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        infoToast(translate("contact.flash.sending"));
        console.log(formData);
        try {
            const response = await axios({
                url: 'https://portfolio.ramzi-issiakhem.com/email.php',
                method: 'POST',
                data: formData
            });

            console.log(response.data);
            succesToast(translate("contact.flash.success"));  
        }  catch (error) {
            console.error("Error", error);
            errorToast(translate("contact.flash.error"));
        }

    };


  useEffect(() => {
    displaySection("mail");
    setActualSection("mail");
  }, []);

  return (
    <section className="section" ref={props.forwardRef} id="contact">
      <div className="contact-main-wrapper">
        <div>
          <div className="contact-left-part">
            <img src={contactImg} alt="TEST" />
          </div>

          <div className="contact-right-part">
            <h1
              className="contact-title"
              dangerouslySetInnerHTML={{ __html: translate("contact.title") }}
            />
            <p className="contact-description ">
              {translate("contact.description.first")}
            </p>

            <p
              className="contact-description"
              dangerouslySetInnerHTML={{
                __html: translate("contact.description.second"),
              }}
            />

            <div className="contact-form-container">
              <div className="contact-form-btn-container">
                <button
                  onClick={() => {
                    displaySection("mail");
                  }}
                  className={`btn btn-mail flex-auto ${
                    actualSection === "mail" ? "active" : ""
                  }`}
                >
                  {translate("contact.mail")}
                </button>
                <button
                  onClick={() => {
                    displaySection("phone");
                  }}
                  className={`btn btn-phone flex-auto ${
                    actualSection === "phone" ? "active" : ""
                  }`}
                >
                  {translate("contact.phone")}
                </button>
                <button
                  onClick={() => {
                    window.open(usefulLinks.linkedin, "_blank");
                  }}
                  className={`mr-0 flex-auto btn btn-linkedin ${
                    actualSection === "linkedin" ? "active" : ""
                  }`}
                >
                  {translate("contact.linkedin")}
                </button>
              </div>

              <form onSubmit={handleSubmit} ref={mailSection} className="form-box form-contact-mail">
                <div className="input-box">
                  <p> {translate("contact.inputs.email")} </p>
                  <input
                    required
                    id="mail"
                    className="input"
                    name="email"
                    type="email"
                  />
                </div>

                <div className="input-box">
                  <p>{translate("contact.inputs.topic")}</p>
                  <select
                    required
                    className="input"
                    name="subject"
                    id="subject-select"
                  >
                    <option value="Hiring">
                      {translate("contact.inputs.topics.hiring")}
                    </option>
                    <option value="More Informations">
                      {translate("contact.inputs.topics.more-info")}
                    </option>
                    <option value="Interview">
                      {translate("contact.inputs.topics.interview")}
                    </option>
                    <option value="Other Request">
                      {translate("contact.inputs.topics.other")}
                    </option>
                  </select>
                </div>

                <div className="input-box">
                  <p>{translate("contact.inputs.message")}</p>
                  <textarea
                    required
                    cols={50}
                    rows={3}
                    name="message"
                    className="input long-text-box"
                    type="text"
                  />
                </div>
                <input
                  className="btn btn-mail w-full"
                  type="submit"
                  value={translate("contact.inputs.send")}
                />
              </form>

              <div ref={phoneSection} className=" form-box form-contact-phone">
                <p>
                  You can call me on {usefulLinks.phoneNumber} or just click{" "}
                  <a
                    className="text-dark-text-secondary text-lg"
                    href={usefulLinks.phoneNumberLink}
                  >
                    here
                  </a>
                  !
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;


//images import
import darkLogo from '../assets/navbar/logo_white.png';
import lightLogo from '../assets/navbar/logo_black.png';
import paramsLogo from '../assets/navbar/settings.png';

//helpers
import {LinkMap} from '../helpers/globals';
import {findIndexByValue, isDarkTheme, translate } from '../helpers/functions';


//rrd imports
import { Link, Navigate } from 'react-router-dom';
import { useEffect, useRef,useState } from 'react';


const Navbar = ({sectionsList,scrollTo,navBarRef,actualPage}) => {
    
    
    const navBarDropRef = useRef(null);
    const sideNavBarRef = useRef(null)
    const state = useRef(false);
    const selectorBox = useRef(false);
    const [hidden,setHidden] = useState(true);
    
    const onMouseOverSideNavbar = (e) => {
        let element = e.target;
        if (element.tagName.toLowerCase() === 'li') {
                changeToSelectedNavLink(element)
        }
    }

    const onMouseEnterSideNavbar = (e) => {

        if (state.current) return;
            sideNavBarRef.current.addEventListener("mouseover",onMouseOverSideNavbar)        
    }

    let onMouseOutSideNavbar = (event) => {
            state.current = false;
            sideNavBarRef.current.removeEventListener("mouseover",onMouseOverSideNavbar)
            changeToSelectedNavLink(actualPage);
    }


    let changeToSelectedNavLink = (element) => {

        let text  = LinkMap.home;
        let index = 0;
        if (element) {
            text = LinkMap[element.id];
            index = findIndexByValue(LinkMap,text);
        }

        let navList = document.querySelectorAll(".navbar-side-list li");

        navList.forEach((element,index) => {
            element.classList.remove("active");
            element.innerHTML = `0${index + 1}`;
        });

        navList[index].innerHTML = translate(text);
        navList[index].classList.toggle("active");

        let value = index*21;
        selectorBox.current.style.top = `${value}%`;

        
    }

    useEffect(() => {
        if (!hidden) {
          navBarDropRef.current.style.transform = "translateX(0)";
        } else {
          navBarDropRef.current.style.transform = "translateX(-100%)";
        }
        changeToSelectedNavLink(actualPage)
    },[actualPage,hidden])



    

    return (
      <nav className="" ref={navBarRef}>
        <div className="navbar">
          <Link to="/" className="navbar-logo-container">
            <img
              src={isDarkTheme() ? darkLogo : lightLogo}
              alt="Navbar logo"
              className="navbar-logo-desktop"
            />

            <p className="navbar-logo-name">Issiakhem Ramzi</p>
          </Link>

          {/* TODO HIRING ACTION */}
          <div className="navbar-btn-container">
            <div
              onClick={() => {
                scrollTo(sectionsList.contact);
                document.querySelector("#subject-select").value =
                  "informations";
              }}
              className="btn hidden sm:block btn-black text-center"
            >
              {translate("nav.contact")}
            </div>

            <div
              onClick={() => {
                scrollTo(sectionsList.contact);
                document.querySelector("#subject-select").value = "hiring";
              }}
              className="btn btn-green"
            >
              {translate("nav.hire-me")}
            </div>
          </div>
        </div>

        <div className="navbar-mobile">
          <div className="navbar-logo-container">
            <img
              src={paramsLogo}
              alt="Navbar logo"
              onClick={() => {
                setHidden(!hidden);
              }}
            />

            <div ref={navBarDropRef} className="navbar-drop-list">
              <div className="navbar-drop-list-wrapper">
                <div className="navbar-drop-list-header cursor-pointer">
                  <p
                    onClick={() => {
                      scrollTo(sectionsList.home);
                    }}
                  >
                    Ramzi
                  </p>
                  <i
                    className="fa-solid fa-xmark fa-lg"
                    onClick={() => {
                      setHidden(true);
                    }}
                  ></i>
                </div>
                <hr className="border-2 my-0 mb-7  bg-black left-0 right-0" />
                <div
                  className="navbar-drop-list-item"
                  onClick={() => {
                    scrollTo(sectionsList.home);
                  }}
                >
                  <i className="fa-solid fa-house fa-lg"></i>
                  <p>{translate("nav.home")}</p>
                </div>
                <div
                  className="navbar-drop-list-item"
                  onClick={() => {
                    scrollTo(sectionsList.about);
                  }}
                >
                  <i className="fa-solid fa-user fa-lg"></i>
                  <p>{translate("nav.about")}</p>
                </div>
                <div
                  className="navbar-drop-list-item"
                  onClick={() => {
                    scrollTo(sectionsList.skills);
                  }}
                >
                  <i className="fa-solid fa-laptop-code fa-lg"></i>
                  <p>{translate("nav.skills")}</p>
                </div>
                <div
                  className="navbar-drop-list-item"
                  onClick={() => {
                    scrollTo(sectionsList.projects);
                  }}
                >
                  <i className="fa-solid fa-list-check fa-lg"></i>
                  <p>{translate("nav.projects")}</p>
                </div>
                <div
                  className="navbar-drop-list-item"
                  onClick={() => {
                    scrollTo(sectionsList.contact);
                  }}
                >
                  <i className="fa-solid fa-phone fa-lg"></i>
                  <p>{translate("nav.contact")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-btn-container">
            <div
              onClick={() => {
                scrollTo(sectionsList.contact);
                document.querySelector("#subject-select").value = "hiring";
              }}
              className="btn btn-green"
            >
              {translate("nav.hire-me")}
            </div>
          </div>
        </div>

        <div
          ref={sideNavBarRef}
          onMouseEnter={onMouseEnterSideNavbar}
          onMouseLeave={onMouseOutSideNavbar}
          className="navbar-side"
        >
          <ul className="navbar-side-list">
            <li
              id="home"
              onClick={() => {
                scrollTo(sectionsList.home);
              }}
            >
              01
            </li>
            <li
              id="about"
              onClick={() => {
                scrollTo(sectionsList.about);
              }}
            >
              02
            </li>
            <li
              id="skills"
              onClick={() => {
                scrollTo(sectionsList.skills);
              }}
            >
              03
            </li>
            <li
              id="projects"
              onClick={() => {
                scrollTo(sectionsList.projects);
              }}
            >
              04
            </li>
            <li
              id="contact"
              onClick={() => {
                scrollTo(sectionsList.contact);
              }}
            >
              05
            </li>

            <div ref={selectorBox} className="selector-box"></div>
          </ul>
        </div>
      </nav>
    );
}
 
export default Navbar;
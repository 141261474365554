//rrd imports
import { Outlet } from "react-router-dom";
import { useEffect, useState,    useRef } from "react";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

// Components Import
import Navbar from "./Navbar";
import HomePage from "../pages/HomePage";
import AboutMePage from "../pages/AboutMePage";
import SkillsPage from "../pages/SkillsPage";
import ProjectsPage from "../pages/ProjectsPage";
import ContactPage from "../pages/ContactPage";
import SideBar from "./SideBar";
import FooterComponent from "./Footer";
import { changeLanguage } from "../helpers/functions";

//helpers




const RootComponent = () => {
    
    const sectionHome = useRef(null);
    const sectionAboutMe = useRef(null);
    const sectionSkills = useRef(null);
    const sectionProjects = useRef(null);
    const sectionContact = useRef(null);
    const mainWrapperRef = useRef(null);
    const navBarRef        = useRef(null);
    const [updated,setUpdated] = useState(false);
    const [actualPage,setActualPage] = useState(null);
    const [lang, setLang] = useState('en');

    useEffect(() => {
        if (localStorage.getItem("lang") == null) {
            localStorage.setItem("lang", "en");
        }
        setLang(localStorage.getItem("lang"));

    }, []);

    const scrollContainerRef = useRef(null);

    const updateTheme = () => {
        setUpdated(!updated);        
    }

    const sectionsList = 
    {
        home:     sectionHome,
        about:    sectionAboutMe,
        skills:   sectionSkills,
        projects: sectionProjects,
        contact:  sectionContact
    };
    
    
    const scrollTo = (section) => {
        const element = section.current;

        element.scrollIntoView(
            {
                behavior: "smooth",

            }
        );
        setActualPage(element)
    
    };

  


    const isSnapped = (container) => {
        // Get the scroll position and container width
        const scrollTop = container.scrollTop;
        const containerHeight = container.offsetHeight;
        
        let arr = Object.values(sectionsList).map((value) => value.current);
        
        // Check if the scroll position is snapped
        arr.forEach((item) => {
            const itemTop = item.offsetTop;
            const itemHeight = item.offsetHeight;

            // Check if the scroll position is within the bounds of the current section
            if ((scrollTop >= itemTop && scrollTop < itemTop + itemHeight) ) {
                setActualPage(item);
            }
        });

    }

    
    return (
    
        <>
            <div className="main-wrapper" ref={mainWrapperRef}>
                <Navbar actualPage={actualPage} navBarRef={navBarRef} sectionsList={sectionsList} scrollTo={scrollTo}/>      
                <SideBar lang={lang} setLang={setLang} updateFnc={updateTheme} />
                <div ref={scrollContainerRef} className="section-container" onScroll={() => isSnapped(scrollContainerRef.current)}>
                    
                    <HomePage     forwardRef={sectionHome} scrollTo={scrollTo} sectionsList={sectionsList}  updateFnc={updateTheme}   />
                    <AboutMePage  forwardRef={sectionAboutMe}   />
                    <SkillsPage   forwardRef={sectionSkills}    />
                    <ProjectsPage forwardRef={sectionProjects}  />
                    <ContactPage  forwardRef={sectionContact}   />
                    <FooterComponent />
                </div>          

            </div>
        </>
    );
}
 
export default RootComponent;
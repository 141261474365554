import decorationImg from '../../assets/projects/decoration.png'

const ProjectCardAppPreview = ({preview}) => {
  
    
    return (  


        <div className="app-preview">
                <img src={preview} alt="" className="app-preview-img" />
        </div>
    );
}
 
export default ProjectCardAppPreview;

//image imports
import CssLogo from "../assets/skills/skill_css.png"
import DockerLogo from "../assets/skills/skill_docker.png"
import DockerComposeLogo from "../assets/skills/skill_docker_compose.png"
import GitLogo from "../assets/skills/skill_git.png"
import HtmlLogo from "../assets/skills/skill_html.png"
import JsLogo from "../assets/skills/skill_js.png"
import LaravelLogo from "../assets/skills/skill_laravel.png"
import NodeLogo from "../assets/skills/skill_node.png"
import SymfonyLogo from "../assets/skills/skill_symfony.png"
import ReactLogo from "../assets/skills/skill_react.png"
import TailwindLogo from "../assets/skills/skill_tailwind.png"
import VsCodeLogo from "../assets/skills/skill_vs_code.png"
import { translate } from "../helpers/functions";


const SkillsPage = (props) => {
    

    const linkedinLink = "https://www.linkedin.com/in/mohamed-ramzi-issiakhem-4b9b8b1aa/";


    return (
      <section className="section" ref={props.forwardRef} id="skills">
        <div className="skills-section-wrapper">
          <div className="skills-section-main">
            <div className="description-box">
              <p className="quote">{translate("skills.quote")}</p>
              <p className="title">{translate("skills.title")}</p>

              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: translate("skills.description.first"),
                }}
              />
              <p
                className="description-2"
             >

                {translate("skills.description.second")}
              <span className="text-blue-600 cursor-pointer"
                onClick={() => {
                  window.open(linkedinLink);
                }}
              >{translate("skills.description.third")}</span>
                </p>
            </div>

            <div className="grid-skills">
              <div className="col-skills">
                <img src={HtmlLogo} alt="HTML Logo" />
                <img src={CssLogo} alt="CSS Logo" />
                <img src={ReactLogo} alt="React Logo" />
                <img src={TailwindLogo} alt="Tailwind Logo" />
                <img src={LaravelLogo} alt="Laravel Logo" />
                <img src={SymfonyLogo} alt="Symfony Logo" />
                <img src={NodeLogo} alt="Node Logo" />
                <img src={JsLogo} alt="JS Logo" />
                <img src={DockerLogo} alt="Docker Logo" />
                <img src={GitLogo} alt="GIT Logo" />
                <img src={DockerComposeLogo} alt="Docker Compose Logo" />
                <img src={VsCodeLogo} alt="VS CODE Logo" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
 
export default SkillsPage;
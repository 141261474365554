// React Imports
import {createBrowserRouter, BrowserRouter as Router, RouterProvider,Route, createRoutesFromElements, Routes} from 'react-router-dom';



// Layout Imports
import PortfolioLayout from './layouts/PortfolioLayout';

// Components & Pages Imports
import Main from "./pages/Main"
import HomePage from './pages/HomePage';
import RootComponent from './components/RootComponent';
import SkillsPage from './pages/SkillsPage';
import ErrorPage from './pages/error/ErrorPage';
import NotFoundPage from './pages/error/NotFoundPage';
import ContactPage from './pages/ContactPage';
import ProjectsPage from './pages/ProjectsPage';
import AboutMePage from './pages/AboutMePage';
import { initGlobalTheme } from './helpers/functions';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';


// Projects Imports





    const router = createBrowserRouter(
    
      createRoutesFromElements( 
            <Route path="/" element={<RootComponent />} errorElement={<ErrorPage />} />
        )
    );

    

function App() {

  initGlobalTheme();
  return  (
  
  <RouterProvider router={router} />)
}


export default App;

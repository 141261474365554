// React Imports
import { Outlet, NavLink } from "react-router-dom"


// Components Import
//import Navbar from '../components/Navbar';


const MainLayout = () => {
    return (        
        <div className='mainLayout'>

                      
        </div>
    );
}
 
export default MainLayout;
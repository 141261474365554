import { ProjectCategory } from "../config/global-config";
import { Projects } from "../config/global-config";
import { toast } from "react-toastify";

export function findIndexByValue(obj, value) {
  let i = 0;
  for (const key in obj) {
    if (obj[key] === value) {
      return i; // Return the key (index) for the given value
    }
    i++;
  }
  return -1; // Return -1 if the value is not found
}

export function succesToast(message) {
  return toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light",
  });
}

export function infoToast(message) {
  return toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light",
  });
}

export function errorToast(message) {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light",
  });
}



export function initGlobalTheme() {
  if (!("theme" in localStorage)) {
    localStorage.theme = "light";
  } else {
    if (localStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
    }
  }
}

export function changeGlobalTheme() {
  if (!("theme" in localStorage)) {
    localStorage.theme = "dark";
    document.documentElement.classList.add("dark");
  } else {
    if (localStorage.theme === "light") {
      document.documentElement.classList.add("dark");
      localStorage.theme = "dark";
    } else {
      document.documentElement.classList.remove("dark");
      localStorage.theme = "light";
    }
  }
}

export function changeLanguage(lang,setLang) {  
  localStorage.setItem("lang", lang);  
  setLang(lang);
  console.log("🚀 ~ changeLanguage ~ lang:", lang);

}

export function isDarkTheme() {
  return document.documentElement.classList.contains("dark");
}

export function getProjectsByType(type) {
  let projects = Projects;
  let arr = [];
  if (type == ProjectCategory.all) {
    return projects;
  }

  arr = projects.map((project) => {
    if (project.category === type) {
      return project;
    }
  });

  console.log(arr);
  if (arr[0] == null) return [];
  else return arr;
}

export function translate(key) {
  let lang = localStorage.getItem("lang");
  if (lang == null) lang = "en";
  let translations = require(`../config/translation-${lang}.json`);


   const keys = key.split(".");
   let current = translations;

   for (const k of keys) {
     if (current[k]) {
       current = current[k];
     } else {
      console.log("🚀 ~ translate ~ current:", key);

       return key; // Return the original key if translation is not found
     }
  
    }

   return current;
}

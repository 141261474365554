// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` /* TODO ANIMATE THE BUTTONS TO PUSH THE USER TO CLICK ON IT */
 
 .btn {
    border-radius: 1rem;
    border-width: 2px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
}
 
 .btn:hover {
    cursor: pointer;
}
 
 @media (min-width: 1300px) {
 
    .btn {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
}
 
 @media (min-width: 1920px) {
 
    .btn {
        margin-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

  .btn:hover {
    transform: translateY(2.5px) scale(1.05);
    transition: all 0.2s ease-in;
    z-index: 5000;
  }
  
  
  .btn-black {
    display: none;
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
  
  
  .btn-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
  
  
  :is(.dark .btn-black) {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
  
  
  :is(.dark .btn-black):hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
  
  
  @media (min-width: 1024px) {
 
    .btn-black {
        display: flex;
    }
}
  
  .btn-green {
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(0 218 100 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(0 218 100 / var(--tw-text-opacity));
}
  
  .btn-green:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 218 100 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}`, "",{"version":3,"sources":["webpack://./src/assets/style/utils.css"],"names":[],"mappings":"CAAC,6DAA6D;;CAG1D;IAAA,mBAOA;IAPA,iBAOA;IAPA,oBAOA;IAPA,qBAOA;IAPA,oBAOA;IAPA,uBAOA;IAPA,eAOA;IAPA,mBAOA;IAPA;AAOA;;CAPA;IAAA;AAOA;;CAPA;;IAAA;QAAA,mBAOA;QAPA;IAOA;AAAA;;CAPA;;IAAA;QAAA,gBAOA;QAPA,kBAOA;QAPA,mBAOA;QAPA,mBAOA;QAPA,sBAOA;QAPA,kBAOA;QAPA;IAOA;AAAA;;EAGF;IACE,wCAAwC;IACxC,4BAA4B;IAC5B,aAAa;EACf;;;EAIE;IAAA,aAKA;IALA,sBAKA;IALA,mDAKA;IALA,oBAKA;IALA;AAKA;;;EALA;IAAA,kBAKA;IALA,mDAKA;IALA,oBAKA;IALA;AAKA;;;EALA;IAAA,sBAKA;IALA,yDAKA;IALA,oBAKA;IALA;AAKA;;;EALA;IAAA,kBAKA;IALA,yDAKA;IALA,oBAKA;IALA;AAKA;;;EALA;;IAAA;QAAA;IAKA;AAAA;;EAIA;IAAA,iBAGC;IAHD,sBAGC;IAHD,uDAGC;IAHD,oBAGC;IAHD;AAGC;;EAHD;IAAA,kBAGC;IAHD,uDAGC;IAHD,oBAGC;IAHD;AAGC","sourcesContent":[" /* TODO ANIMATE THE BUTTONS TO PUSH THE USER TO CLICK ON IT */\n \n .btn {\n    @apply border-2 rounded-2xl font-bold \n    px-6 py-1 \n    2xl:px-8 2xl:py-2 2xl:mt-4 \n    text-base\n    lg:text-lg\n    2xl:text-xl\n    hover:cursor-pointer\n    ; \n  }\n\n  .btn:hover {\n    transform: translateY(2.5px) scale(1.05);\n    transition: all 0.2s ease-in;\n    z-index: 5000;\n  }\n  \n  \n  .btn-black {\n    @apply\n        hidden md:flex \n        text-light-text-primary\n        border-black dark:border-dark-text-primary dark:text-dark-text-primary\n        hover:text-white hover:bg-light-text-primary hover:dark:bg-dark-text-primary hover:dark:text-dark-bg\n    ;\n  }\n  \n  .btn-green {\n    @apply text-light-text-secondary\n        border-2 border-light-text-secondary\n        hover:bg-light-text-secondary hover:text-white \n     ;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

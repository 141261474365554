import MeLogo from '../assets/about/me.png';
import { translate } from '../helpers/functions';


const AboutMePage = (props) => {
    
    return (
      <section className="section" ref={props.forwardRef} id="about">
        <div className="presentation-container-mobile">
          <div className="mobile-about-me-title-box">
            <div className="mobile-left-part">
              <h5
                className="mobile-title"
                dangerouslySetInnerHTML={{
                  __html: translate("about.about-me"),
                }}
              />
              <p
                className="mobile-position"
                dangerouslySetInnerHTML={{
                  __html: translate("about.position"),
                }}
              />
            </div>

            <div className="mobile-right-part">
              <img src={MeLogo} alt="Logo of Me :D" />
            </div>
          </div>

          <div className="mobile-about-me-description-box">
            <p
              dangerouslySetInnerHTML={{
                __html: translate("about.description.first"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: translate("about.description.second"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: translate("about.description.third"),
              }}
            />
          </div>
        </div>

        <div className="presentation-container-desktop">
          <div className="presentation-main-desktop">
            <img className="left-part" src={MeLogo} alt="Logo of Me :D" />

            <div className="right-part">
              <div className="about-me-box">
                <h5
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: translate("about.about-me"),
                  }}
                />
                <p className="position">{translate("about.position")}</p>
              </div>

              <div className="about-me-description-box">
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate("about.description.first"),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate("about.description.second"),
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: translate("about.description.third"),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
 
export default AboutMePage;
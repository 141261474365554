import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
    
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return (
        <div>{error.data}</div>
    );
}
 
export default ErrorPage;
import decorationImg from '../../assets/projects/decoration.png'
import { useRef } from 'react';

const ProjectCardWebPreview = ({preview}) => {
   

    /* TODO PREVIEW */ 

    return (  


        <div className="web-preview">
                <img src={preview} alt="" className="web-preview-img" />
        </div>
    );
}
 
export default ProjectCardWebPreview;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.navbar-side {
    position: absolute;
    right: 3px;
    top: 40%;
    margin-right: 1rem;
    display: none;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark .navbar-side) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (min-width: 1024px) {
    .navbar-side {
        display: block;
    }
}
.navbar-side {
    z-index: 100;
  }

  .navbar-side-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

  @media (min-width: 1024px) {
    .navbar-side-list {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

  @media (min-width: 1920px) {
    .navbar-side-list {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}

  .navbar-side-list { 
    font-family: MontSerrat;
  }


  .navbar-side-list li {
    margin-top: 0.125rem;
    min-width: 70px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-align: right;
}


  .navbar-side-list li:hover {
    cursor: pointer;
}


  @media (min-width: 1024px) {
    .navbar-side-list li {
        min-width: 80px;
    }
}


  @media (min-width: 1440px) {
    .navbar-side-list li {
        margin-top: 0.25rem;
    }
}

  .navbar-side-list::after {
    content: "";
    position: absolute;
    top: 0.375rem;
    right: -0.625rem;
    height: 95%;
    width: 1.5px;
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }

  :is(.dark .navbar-side-list)::after {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

  @media (min-width: 1440px) {
    .navbar-side-list::after {
        height: 97%;
    }
}
  
  .selector-box {
    position: absolute;
    right: -0.625rem;
    top: 0px;
    height: 1.75rem;
    width: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
  
  :is(.dark .selector-box) {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
  
  @media (min-width: 1440px) {
    .selector-box {
        height: 2rem;
    }
}
  
  .selector-box {

    transition: top 1.5s ease;
  }`, "",{"version":3,"sources":["webpack://./src/assets/style/navbar/sidebar.css"],"names":[],"mappings":";AAEI;IAAA,kBAMA;IANA,UAMA;IANA,QAMA;IANA,kBAMA;IANA,aAMA;IANA,oBAMA;IANA;AAMA;AANA;IAAA,oBAMA;IANA;AAMA;AANA;IAAA;QAAA;IAMA;AAAA;AAPJ;IAQI,YAAY;EACd;;EAGE;IAAA,kBAMA;IANA,aAMA;IANA,sBAMA;IANA,qBAMA;IANA,yBAMA;IANA,mBAMA;IANA;AAMA;;EANA;IAAA;QAAA,eAMA;QANA;IAMA;AAAA;;EANA;IAAA;QAAA,kBAMA;QANA;IAMA;AAAA;;EAPF;IAQE,uBAAuB;EACzB;;;EAIE;IAAA,oBAKsB;IALtB,eAKsB;IALtB,oBAKsB;IALtB,uBAKsB;IALtB;AAKsB;;;EALtB;IAAA;AAKsB;;;EALtB;IAAA;QAAA;IAKsB;AAAA;;;EALtB;IAAA;QAAA;IAKsB;AAAA;;EAIxB;IACE,WAAW;IAEX,kBAI6B;IAJ7B,aAI6B;IAJ7B,gBAI6B;IAJ7B,WAI6B;IAJ7B,YAI6B;IAJ7B,kBAI6B;IAJ7B,mDAI6B;EAC/B;;EALE;IAAA,kBAI6B;IAJ7B;AAI6B;;EAJ7B;IAAA;QAAA;IAI6B;AAAA;;EAI7B;IAAA,kBAG2B;IAH3B,gBAG2B;IAH3B,QAG2B;IAH3B,eAG2B;IAH3B,cAG2B;IAH3B,kBAG2B;IAH3B;AAG2B;;EAH3B;IAAA,kBAG2B;IAH3B;AAG2B;;EAH3B;IAAA;QAAA;IAG2B;AAAA;;EAJ7B;;IAME,yBAAyB;EAC3B","sourcesContent":["\n.navbar-side {\n    @apply \n      hidden\n      md:block\n      absolute right-[3px] top-[40%]\n      mr-4\n      text-light-text-primary dark:text-dark-text-primary\n    ;\n    z-index: 100;\n  }\n\n  .navbar-side-list {\n    @apply\n      flex flex-col justify-end items-end\n      relative\n      text-sm \n      md:text-base \n      2xl:text-xl\n    ; \n    font-family: MontSerrat;\n  }\n\n\n  .navbar-side-list li {\n    @apply \n      text-right \n      min-w-[70px] md:min-w-[80px] \n      mt-0.5 py-0.5\n      xl:mt-1 py-1\n      hover:cursor-pointer;\n      \n  }\n\n  .navbar-side-list::after {\n    content: \"\";\n    \n    @apply \n      w-[1.5px] h-[95%]\n      xl:h-[97%]\n      bg-light-text-primary dark:bg-dark-text-primary\n      absolute top-1.5 -right-2.5;\n  }\n  \n  .selector-box {\n    @apply \n      w-1 h-7  xl:h-8\n      bg-light-text-primary dark:bg-dark-text-primary\n      absolute -right-2.5 top-0;\n\n    transition: top 1.5s ease;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

//rrd
import { useRef, useState } from "react";

// components import
import ProjectCard from "../components/projects/ProjectCard";
import { getProjectsByType, translate } from "../helpers/functions";

//helpers import
import { ProjectCategory } from "../config/global-config";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//img imports

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "green" }}
      onClick={onClick}
    />
  );
}

const ProjectsPage = (props) => {
  const [currentCategory, setCurrentCategory] = useState(ProjectCategory.all);
  const cards = useRef([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const displayCards = () => {
    /* TODO WHEN LIST IS EMPTY AND YOU COME BACK TO FULL LIST -> GOTO FIRST INDEX */

    let arr = getProjectsByType(currentCategory);
    cards.current = arr;

    return arr.map((project, index) => {
      return (
        <ProjectCard
          key={index}
          category={project.category}
          type={project.type}
          title={project.title}
          description={project.description}
          technologies={project.technologies}
          previewImg={project.previewImg}
          previewLink={project.previewLink}
          codeLink={project.codeLink}
        />
      );
    });
  };

  return (
    <section className="section" ref={props.forwardRef} id="projects">
      <div className="projects-main-wrapper">
        <div className="w-full">
          <div className="projects-header">
            <p
              className="projects-header-title"
              dangerouslySetInnerHTML={{ __html: translate("projects.title") }}
            />
            <div className="projects-header-btn-wrapper">
              <div
                onClick={() => {
                  setCurrentCategory(ProjectCategory.all);
                }}
                className={`btn-green-black btn mr-2 ${
                  currentCategory === ProjectCategory.all
                    ? "dark:bg-dark-text-secondary dark:text-white"
                    : ""
                }`}
              >
                {translate("projects.category.all")}
              </div>
              <div
                onClick={() => {
                  setCurrentCategory(ProjectCategory.web);
                }}
                className={`btn-green-black btn mr-2 ${
                  currentCategory === ProjectCategory.web
                    ? "dark:bg-dark-text-secondary dark:text-white"
                    : ""
                }`}
              >
                {translate("projects.category.web")}
              </div>
              <div
                onClick={() => {
                  setCurrentCategory(ProjectCategory.app);
                }}
                className={`btn-green-black btn  ${
                  currentCategory === ProjectCategory.app
                    ? "dark:bg-dark-text-secondary dark:text-white"
                    : ""
                }`}
              >
                {translate("projects.category.mobile")}
              </div>
            </div>
          </div>

          <hr className="projects-separator" />

          <Slider {...settings} className="projects-list-wrapper">
            {displayCards()}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ProjectsPage;

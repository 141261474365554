//react improts
import { useEffect, useRef, useState } from "react";

// logo imports
import normalMeLogo from "../assets/home/normal_me.png";
import projectorBlackWithLight from "../assets/home/projector_black_with_light.png";
import projectorBlack from "../assets/home/projector_black.png";
import projectorWhite from "../assets/home/projector_white.png";
import arrowIcon from "../assets/home/arrow_icon.png";

//heleprs import
import { isDarkTheme, changeGlobalTheme, translate } from "../helpers/functions";
import { LinkMap } from "../helpers/globals";

const HomePage = ({ updateFnc, forwardRef, scrollTo, sectionsList }) => {
  const [projector, setProjector] = useState(null);
  const [text, setText] = useState(null);
  const moreAboutMeImg = useRef(null);

  useEffect(() => {
    if (isDarkTheme()) {
      setProjector(projectorWhite);
      setText("Turn me on");
    } else {
      setText("Turn me off");
      setProjector(projectorBlack);
    }
    mouseOutAboutMeBtn();
  }, [isDarkTheme()]);

  const mouseEnterAboutMeBtn = () => {
    moreAboutMeImg.current.style.transform = `rotate(${0}deg)`;
  };

  const mouseOutAboutMeBtn = () => {
    moreAboutMeImg.current.style.transform = `rotate(${90}deg)`;
  };

  const onChangeTheme = () => {
    changeGlobalTheme();
    updateFnc();
  };

  return (
    <section className="section " ref={forwardRef} id="home">
      <div className=" home-main-wrapper">
        <div className="home-left-part">
          <div className="title-box">
            <p className="title-hi">👋 {translate("home.hello-title")}</p>
            <p className="title-position">
              {translate("home.position-title.normal")}
              <span className="text-dark-text-secondary">
                {translate("home.position-title.highlight")}
              </span>
            </p>
          </div>

          <div className="home-description-box">
            <p className="home-description">{translate("home.description")}</p>
            <div
              className="more-about-me-btn"
              onClick={() => scrollTo(sectionsList.about)}
              onMouseEnter={mouseEnterAboutMeBtn}
              onMouseLeave={mouseOutAboutMeBtn}
            >
              <p> {translate("home.about-me")}</p>
              <img ref={moreAboutMeImg} src={arrowIcon} alt="Arrow Icon" />
            </div>
          </div>
        </div>

        <div className="person-container">
          <div className="person-container-wrapper">
            <img className="person-img" src={normalMeLogo} alt="Me in Person" />
            <p className="personal-message">Welcome baby</p>

            <img
              className="projector-img "
              onClick={onChangeTheme}
              src={projector}
              alt="Projector"
            />
          </div>
        </div>
      </div>

      {/*!isDarkTheme() &&  (<img className="projector-light-img" src={projectorLight} alt="Projector" />)*/}

      <div className="projector-content"></div>
    </section>
  );
};

export default HomePage;

import { useState } from "react";

// components import
/*import Navbar from "../components/Navbar";
import SubMenu from "../components/SubMenu";
import Home from "../components/Home";
import AboutMe from "../components/AboutMe";
*/

//helpers import
import { initGlobalTheme } from "../helpers/functions";
const Main = () => {

    const [update,setUpdate] = useState(false);
    initGlobalTheme();
    
    return (
        <>
           
        </>
    );
}
 
export default Main;
export const ProjectCategory = {
  app: "app",
  web: "web",
  all: "all",
};

export const usefulLinks = {
  linkedin: "https://www.linkedin.com/in/mohamed-ramzi-issiakhem-4b9b8b1aa/",
  github: "https://github.com/ramzi-issiakhem",
  upwork: "https://www.upwork.com/freelancers/~0122a41f44f1974a7c",
  mail: "mailto:issiakhem.mohamedramzi@gmail.com",
  phoneNumberLink: "tel:+213558457292",
  phoneNumber: "(+213) 558 45 72 92",
};

export const Projects = [
  {
    category: ProjectCategory.web,
    type: "projects.projects-list.walvis.type",
    title: "projects.projects-list.walvis.title",
    description: "projects.projects-list.walvis.description",
    technologies: [
      "Laravel",
      "CSS",
      "VueJS",
      "PHP",
      "MySQL",
      "Redis"
    ],
    previewImg: "img/projects/walvis-preview.png",
    previewLink: "https://walvis.app",
  },
  {
    category: ProjectCategory.web,
    type: "projects.projects-list.crm.type",
    title: "projects.projects-list.crm.title",
    description: "projects.projects-list.crm.description",
    technologies: [
      "CRM",
      "Automation",
      "EspoCRM",
      "PHP",
      "MySQL",
    ],
    previewImg: "img/projects/crm-preview.png",
  },
  {
    category: ProjectCategory.web,
    type: "projects.projects-list.pfe-ooredoo.type",
    title: "projects.projects-list.pfe-ooredoo.title",
    description: "projects.projects-list.pfe-ooredoo.description",
    technologies: [
      "HTML",
      "CSS",
      "Javascript",
      "Vue",
      "PHP",
      "Laravel",
      "MySQL",
    ],
    previewImg: "img/projects/pfe-ooredoo-preview.png",
    //codeLink: "https://www.google.com",
    //previewLink: "https://www.google.com",
  },
  {
    category: ProjectCategory.web,
    type: "projects.projects-list.sos-dz.type",
    title: "projects.projects-list.sos-dz.title",
    description: "projects.projects-list.sos-dz.description",
    technologies: ["HTML", " CSS", " Symfony", " PHP", " MySQL"],
    previewImg: "img/projects/sos-dz-preview.png",
    //codeLink: "https://www.google.com",
    //previewLink: "https://www.google.com",
  },
  {
    category: ProjectCategory.web,
    type: "projects.projects-list.rideshare.type",
    title: "projects.projects-list.rideshare.title",
    description: "projects.projects-list.rideshare.description",
    technologies: ["HTML", "CSS", "Javascript", "React", "NodeJs", "Express"],
    previewImg: "img/projects/rideshare-preview.png",
    //codeLink: "https://www.google.com",
    //previewLink: "https://www.google.com",
  },
  /*{
    category: ProjectCategory.web,
    type: "projects.projects-list.cie-services.type",
    title: "projects.projects-list.cie-services.title",
    description: "projects.projects-list.cie-services.description",
    technologies: ["HTML", "CSS", "PHP", "Laravel", "MySQL"],
    previewImg: "img/projects/pfe-ooredoo-preview.png",
    //codeLink: "https://www.google.com",
    //previewLink: "https://www.google.com",
  },*/

  /*{
    category: ProjectCategory.web,
    type: "projects.projects-list.dz-unity.type",
    title: "projects.projects-list.dz-unity.title",
    description: "projects.projects-list.dz-unity.description",
    technologies: ["HTML", " CSS", " Laravel", " PHP", " MySQL"],
    previewImg: "img/projects/pfe-ooredoo-preview.png",
    //codeLink: "https://www.google.com",
    //previewLink: "https://www.google.com",
  },*/
];

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `aside {
  
    position: fixed;
  
    left: 0.75rem;
  
    bottom: 0.75rem;
  
    z-index: 10
}
  
  .aside-img {
  
    margin-bottom: 0.75rem;
  
    width: 34px
}
  
  .aside-img:hover {
  
    cursor: pointer
}

  .aside-img-main {
  
    width: 50px
}

  @keyframes wiggle {
  
    0% {
  
        transform: rotate(0deg)
    }
  
    5% {
  
        transform: rotate(8deg)
    }
  
    10% {
  
        transform: rotate(-8deg)
    }
  
    15% {
  
        transform: rotate(8deg)
    }
  
    20% {
  
        transform: rotate(-8deg)
    }
  
    25% {
  
        transform: rotate(8deg)
    }
  
    30%,100% {
  
        transform: rotate(0deg)
    }
}

  .aside-img-main {
  
    animation: wiggle 3s ease-in-out infinite
}

  .aside-img-main:hover {
  
    cursor: pointer
}


  .aside-upper-part {
  
    display: flex;
  
    flex-direction: column;
  
    align-items: center;
  
    justify-content: center
}

  .aside-right-part {
  
    position: absolute;
  
    bottom: 0.5rem;
  
    left: 120px;
  
    display: flex;
  
    width: 100%;
  
    flex-direction: row;
  
    align-items: center;
  
    justify-content: center
}

  @media (min-width: 768px) {
  
    .aside-right-part {
  
        left: 130px
    }
}

  .aside-right-part .aside-img {
  
    margin-bottom: 0px;
  
    margin-right: 0.5rem
}

  @media (min-width: 768px) {
  
    .aside-right-part .aside-img {
  
        margin-right: 0.75rem
    }
}`, "",{"version":3,"sources":["webpack://./src/assets/style/navbar/sub_menu.css"],"names":[],"mappings":"AACI;;IAAA,eAGA;;IAHA,aAGA;;IAHA,eAGA;;IAHA;AAGA;;EAIA;;IAAA,sBAAyC;;IAAzC;AAAyC;;EAAzC;;IAAA;AAAyC;;EAIzC;;IAAA;AAAmD;;EAAnD;;IAAA;;QAAA;IAAmD;;IAAnD;;QAAA;IAAmD;;IAAnD;;QAAA;IAAmD;;IAAnD;;QAAA;IAAmD;;IAAnD;;QAAA;IAAmD;;IAAnD;;QAAA;IAAmD;;IAAnD;;QAAA;IAAmD;AAAA;;EAAnD;;IAAA;AAAmD;;EAAnD;;IAAA;AAAmD;;;EAOnD;;IAAA,aAAgD;;IAAhD,sBAAgD;;IAAhD,mBAAgD;;IAAhD;AAAgD;;EAIhD;;IAAA,kBAGA;;IAHA,cAGA;;IAHA,WAGA;;IAHA,aAGA;;IAHA,WAGA;;IAHA,mBAGA;;IAHA,mBAGA;;IAHA;AAGA;;EAHA;;IAAA;;QAAA;IAGA;AAAA;;EAIA;;IAAA,kBACY;;IADZ;AACY;;EADZ;;IAAA;;QAAA;IACY;AAAA","sourcesContent":["aside {\n    @apply fixed  z-10\n    left-3 bottom-3\n    \n    ;\n  }\n  \n  .aside-img {\n    @apply w-[34px] hover:cursor-pointer mb-3;\n  }\n\n  .aside-img-main {\n    @apply w-[50px] hover:cursor-pointer animate-wiggle;\n\n  }\n\n\n  .aside-upper-part {\n\n    @apply flex flex-col justify-center items-center;\n  }\n\n  .aside-right-part {\n    @apply flex flex-row justify-center items-center absolute w-full  bottom-2\n    left-[120px] sm:left-[130px]\n\n    ;\n  }\n\n  .aside-right-part .aside-img {\n    @apply mb-0 \n    mr-2 sm:mr-3;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

//helpers
import { ProjectCategory } from "../../config/global-config";
import { translate } from "../../helpers/functions";
import ProjectCardAppPreview from "./AppPreview";
import ProjectCardWebPreview from "./WebPreview";
const ProjectCard = ({
  category,
  type,
  title,
  description,
  technologies,
  previewImg,
  previewLink = "",
  codeLink = "",
}) => {
  const displayTechnologies = () => {
    return technologies.map((tech, index) => {
      return (
        <div key={tech + index} className="tech-btn">
          {tech}
        </div>
      );
    });
  };

  return (
    <div data-category={category} className="project-container">
      <div className="projects-description-part">
        <div className="text-start">
          <p className="project-card-type">{translate(type)}</p>
          <p className="project-card-title">{translate(title)}</p>
          <p className="project-card-description">
            {translate(description)}
          </p>
        </div>

        <div className="project-card-tech-container">
          <p className="project-card-tech-container-title">{translate('projects.technologies-label')}</p>
          <div className="technologies-list">{displayTechnologies()}</div>
        </div>
        <div className="project-card-btn-container">
          {codeLink !== "" && (
            <div
              onClick={() => window.open(codeLink)}
              className={`btn btn-card ${
                previewLink === "" ? "full-width" : ""
              }`}
            >
              <i className="fa-brands fa-github"></i>
              <p>Github</p>
            </div>
          )}

          {previewLink !== "" && (
            <div
              onClick={() => window.open(previewLink, "_blank")}
              className={`btn btn-card ${codeLink === "" ? "full-width" : ""}`}
            >
              <i className="fa-solid fa-globe"></i>
              <p>{translate("projects.preview")}</p>
            </div>
          )}
        </div>
      </div>

      <div className="project-preview-part">
        {category === ProjectCategory.app && (
          <ProjectCardAppPreview preview={previewImg} />
        )}
        {category === ProjectCategory.web && (
          <ProjectCardWebPreview preview={previewImg} />
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
